import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import PubMedSearchAskQuestionIndex from "../../components/PubMedSearch/PubMedSearchAskQuestionIndex";
import PubMedLeftSearchBoxIndex from "../../components/PubMedSearch/PubMedLeftSearchBoxIndex";
import PubMedNavigationMenu from "../../components/Navigation/PubMedNavigationMenu";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import Footer from "../../components/footer/Footer";
import {
    imsLoader,
} from "../../assets";
import { processPubMedFilters } from "../FilterComponent/ProcessFilter";
import Services from "../../utils/services";
import ApiServices from "../../utils/apiServices";
import { PubMedFilters, LocationOrigin } from "../../utils/utilityServices";
import axios from "axios";
import FilterService from "../../services/FilterService";

class PubMedSearch extends Component {
    userid;
    userExists;
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            username: "",
            userExists: false,
            searchKeyword: "",
            isLoaded: false,
            isProcessing: true,
            response: [],
            allFilters: {},
            documents: [],
            documentCount: 0,
            appliedFilters: [], //Filters that are applied for while search is happening
            selectedFilters: [],
            itemsPerPage: "25",
            currentPage: "1",
            filters: {},
            f1: "",
            fval1: "",
            fieldedSearchArr: {},
            selectedParentFilterArr: [],
            sortByValue: "Relevance",
            sortBy: "",
            sortField: "",
            appliedFiltersForOnlyiMS: [],
            filterYears: [],
            fromYear: "",
            toYear: "",
            error: "",
            errorHTML: "",
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
            isRenderLandingPage: true,
            globalCollapseAll: false /* , globalSelectAll: false */,
            collapsedSearchIDs: [],
            showMajorTopicSearch: false,
            majorTopicSearchState: true,
            selectedResults: [],
            citationSelectStatus: [],
        }
    }
    componentDidMount() {
        try {
            document.title = "PubMed Search";
            this.userid = JSON.parse(localStorage.getItem("username"));
            this.wwid = JSON.parse(localStorage.getItem('wwid'));
            this.userExists = localStorage.getItem("userExists");
            let service = new Services();
            service.logService(
                `PubMedSearch page successfully mounted for ${this.userid.toLowerCase()}`,
                "info"
            );
            if (this.userExists) {
                this.setState({
                    userExists: this.userExists,
                });
                if (this.props.location.search === "") {
                    this.props.history.replace("/PubMedSearch?query=&filters=");
                }
                if (this.props.location.search) {
                    const queryParams = new URLSearchParams(this.props.location.search);
                    const filters = queryParams.get("filters");
                    const query = queryParams.get("query");
                    let _replaceURL = `${this.props.location.pathname}${this.props.location.search}`;

                    if (!query && query !== '') {
                        _replaceURL += `&query=`
                    }
                    if (!filters && filters !== '') {
                        _replaceURL += `&filters=`
                    }
                    this.props.history.replace(_replaceURL);
                }
                const queryParams = new URLSearchParams(this.props.location.search);
                const params = queryParams.get("query");
                const filters = queryParams.get("filters");


                if (filters) {
                    let bifurcateFilters = this.bifurcateAppliedFilters(
                        filters.split(";")
                    );
                    this.setState(
                        {
                            //appliedFilters: filters.split(';')
                            appliedFilters: bifurcateFilters.appliedFiltersForBoth,
                            appliedFiltersForOnlyiMS:
                                bifurcateFilters.appliedFiltersForOnlyiMS,
                            /* f1:f1,
                                  fval1:fval1 */
                        },
                        () => {
                            this.setState({
                                selectedParentFilterArr: this.generateSelectParentArray(
                                    this.state.appliedFilters
                                ),
                            });
                            if (params) {
                                this.runSearch(params, this.state.appliedFilters);
                            }
                        }
                    );
                } else {
                    this.setState(
                        {
                            appliedFilters: [],
                            /* f1:f1,
                                  fval1:fval1 */
                        },
                        () => {
                            this.setState({
                                selectedParentFilterArr: this.generateSelectParentArray(
                                    this.state.appliedFilters
                                ),
                            });
                            if (params) {
                                this.runSearch(params, this.state.appliedFilters);
                            }
                        }
                    );
                }
                if (filters) {
                    this.setState({
                        selectedFilters: filters.split(";"),
                    });
                } else {
                    this.setState({
                        selectedFilters: [],
                    });
                }
                this.setState({
                    searchKeyword: params ? "" : params.query,
                    isRenderLandingPage: params ? false : true,
                    isLoaded: params ? false : true,
                });


                window.addEventListener("resize", this.updateDimensions);
            } else {
                console.log("Access Denied");
                this.props.history.push("/");
            }
        } catch (error) {

        }

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        try {
            this.setState(
                { width: window.innerWidth, height: window.innerHeight });
        } catch (error) {
            this.setState({ error: error });
        }
    };

    handleChangeInSearch = (query) => {
        this.setState(
            {
                searchKeyword: query,
                currentPage: "1",
                isRenderLandingPage: query ? false : true,

            },
            () => {
                if (this.state.error !== "") {
                    this.setState({ error: "" });
                }
                // this.runSearchFromQueryParams(
                //     this.state.allFilters,
                //     this.state.appliedFilters,
                //     query
                // );
            }
        );
    };
    runSearch(querytext, filterSelected) {
        try {
            this.setState({
                isProcessing: true,
                globalCollapseAll: false /* , globalSelectAll: false */,
                collapsedSearchIDs: [],
            });
            var url =
                LocationOrigin +
                process.env.REACT_APP_IMS_API_PREFIX +
                "qp/runPubMedSearch";
            let filterStr = this.generateArrayString(filterSelected);
            var config;
            var data = {};
            let query = querytext;
            let isBoolean = false;
            const queryParams = new URLSearchParams(this.props.location.search);
            /*Check for filededSearch values*/
            const params = queryParams.get("query");
            const fcount = queryParams.get("fcount");
            const f1 = queryParams.get("f1");
            const isDateRange = queryParams.get("isDateRange");
            /*Check for sbQuery*/
            // if (sbQuery !== null) {
            //   query = sbQuery;
            // }
            /*Check for boolean operator in Query String Start*/
            isBoolean = queryParams.get("isBoolean");
            let booleanCounter;
            if (query) {
                booleanCounter = (query.match(/ OR | AND | NOT | NEAR /g) || []).length;
            }
            if (isBoolean !== null || booleanCounter >= 4) {
                isBoolean = true;
            } else if (booleanCounter >= 1 && query.split(" ").length <= 10) {
                isBoolean = true;
            } else {
                isBoolean = false;
            }
            /*Check for boolean operator in Query String End*/
            if (params !== "" && f1 === "majorTopicsSynonyms") {
                this.setState({
                    showMajorTopicSearch: true,
                });
            } else if (
                (params !== "" && f1 === undefined) ||
                f1 === null ||
                f1 === ""
            ) {
                this.setState({
                    showMajorTopicSearch: true,
                });
            } else if (params !== "" && f1 !== "majorTopicsSynonyms") {
                this.setState({
                    showMajorTopicSearch: false,
                });
            }
            if (f1 === "majorTopicsSynonyms") {
                this.setState({
                    majorTopicSearchState: false,
                    showMajorTopicSearch: true,
                });
            }
            if (fcount > 0) {
                data = {
                    query: query,
                    filters: filterStr,
                    from:
                        this.state.currentPage * this.state.itemsPerPage -
                        this.state.itemsPerPage,
                    size: parseInt(this.state.itemsPerPage),
                    sortBy: this.state.sortBy,
                    sortField: this.state.sortField,
                    fromYear: this.state.fromYear,
                    toYear: this.state.toYear,
                };
                config = {
                    method: "GET",
                    url: url,
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
            } else {
                config = {
                    method: "GET",
                    url: url,
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
                data = {
                    query: query,
                    filters: filterStr,
                    from:
                        this.state.currentPage * this.state.itemsPerPage -
                        this.state.itemsPerPage,
                    size: parseInt(this.state.itemsPerPage),
                    sortBy: this.state.sortBy,
                    sortField: this.state.sortField,
                    fromYear: this.state.fromYear,
                    toYear: this.state.toYear,
                };
            }
            if (isDateRange !== null) {
                let rangeField = queryParams.get("rangeField");
                let toRange = queryParams.get("toRange");
                let fromRange = queryParams.get("fromRange");
                data["rangeField"] = rangeField;
                data["isDateRange"] = true;
                if (toRange !== null) {
                    data["toRange"] = toRange;
                }
                if (fromRange !== null) {
                    data["fromRange"] = fromRange;
                }
            }
            const queryparams = new URLSearchParams(data).toString();
            config.url = `${url}?${queryparams}`;
            axios(config)
                .then((response) => {
                    var processed = processPubMedFilters(response.data.body);
                    // var processedSilterYears = this.handelfilterYears(
                    //   response.data.body.yearBuckets
                    // );
                    const filterService = new FilterService();
                    const [filters] = filterService.expandFilterSet(
                        processed,
                        this.state.appliedFilters
                    );
                    let selectedFiltered = this.state.selectedFilters.concat(filters);
                    // selectedFiltered = [...new Set(selectedFiltered)]
                    selectedFiltered = this.state.appliedFilters.concat(filters);
                    this.setState(
                        {
                            documentCount: response.data.body.hits.total.value,
                            response: response.data.body.hits.hits,
                            filters: processed,
                            isProcessing: false,
                            isLoaded: true,
                            collapseFilter: false,
                            filterYears: response.data.body.yearBuckets.sort(
                                (a, b) => parseFloat(a.key) - parseFloat(b.key)
                            ),
                            selectedFilters: selectedFiltered,
                            //filterYears: processedSilterYears
                        },
                        () => {
                            if (
                                this.state.globalSelectAll &&
                                (this.state.citationSelectStatus.length.toString() ===
                                    this.state.itemsPerPage ||
                                    this.state.citationSelectStatus.length ===
                                    this.state.documentCount)
                            ) {
                                this.setState({ globalSelectAll: true });
                            } else {
                                this.setState({ globalSelectAll: false });
                            }
                            this.handleAllFilters(
                                this.state.filters,
                                this.state.appliedFilters,
                                this.state.selectedFilters,
                                this.state.response,
                                this.state.documentCount,
                                querytext
                            );
                        }
                    );
                    // VIEW SELECTED LOGIC
                    let data = this.state.selectedResults;
                    let citationSelectStatus = this.state.citationSelectStatus;
                    let markedUrl = this.markedDocumentsUrl;
                    var sessiondata = sessionStorage.getItem("selectedResultsDetails");
                    // for (let index = 0; index < this.state.documentCount; index++) {
                    if (localStorage.getItem("selectedResults") === null) {
                        // console.log(this.props.location.search,this.state.prevUrl,window.location.search)
                        if (window.location.search !== this.state.prevUrl) {
                            data = [];
                            citationSelectStatus = [];
                            markedUrl = [];
                        }
                        /* else {
                                      citationSelectStatus.push(false)
                                  } */
                    }
                    if (
                        localStorage.getItem("selectedResults") !== null &&
                        sessiondata !== null
                    ) {
                        if (
                            decodeURI(this.props.location.search) !==
                            decodeURI(this.state.prevUrl)
                        ) {
                            data = [];
                            citationSelectStatus = [];
                            localStorage.removeItem("selectedResults");
                            localStorage.removeItem("ItemsPerPage");
                            localStorage.removeItem("ActivePage");
                            localStorage.removeItem("markedResultsUrl");
                            sessionStorage.clear();
                        }
                    }
                    this.markedDocumentsUrl = markedUrl;
                    this.setState({
                        citationSelectStatus: citationSelectStatus,
                        selectedResults: data,
                    });

                })
                .catch((error) => {
                    this.setState({ error: error });
                })
                .finally(() => {
                    this.setState({ isProcessing: false, isLoaded: true });
                });
        } catch (error) {
            this.setState({ error: error });
        }
    }
    generateArrayString = (array) => {
        try {
            let arrStr = "";
            array.map((item, index) => {
                if (index === array.length - 1) {
                    arrStr += item;
                } else {
                    arrStr += item + ";";
                }
                return null;
            });
            return arrStr;
        } catch (error) {
            this.setState({ error: error });
        }
    };
    bifurcateAppliedFilters(appliedFilters) {
        try {
            let appliedFiltersForOnlyiMS = [];
            let appliedFiltersForBoth = [];
            appliedFilters.map((filter) => {
                const filterParts = filter.split("/");
                const filterTop = filterParts[0];
                if (
                    Object.keys(PubMedFilters).findIndex(
                        (x) => x.toLowerCase() === filterTop.toLowerCase()
                    ) > -1
                ) {
                    appliedFiltersForBoth.push(filter);
                } else {
                    appliedFiltersForOnlyiMS.push(filter);
                }
            });
            return {
                appliedFiltersForOnlyiMS: appliedFiltersForOnlyiMS,
                appliedFiltersForBoth: appliedFiltersForBoth,
            };
        } catch (error) {
            this.setState({ error: error });
        }
    }
    generateSelectParentArray = (appliedfilters) => {
        try {
            let parentFacet = [];
            appliedfilters.forEach((filter) => {
                const filterParts = filter.split("/");
                const filterTop = filterParts[0];
                parentFacet.push(filterTop);
            });
            return parentFacet;
        } catch (error) {
            this.setState({ error: error });
        }
    };
    handleAllFilters = (
        filters,
        appliedfilters,
        selectedFilters,
        response,
        docCount,
        query
    ) => {
        try {
            this.setState({
                filters: filters,
                appliedFilters: appliedfilters,
                selectedFilters: selectedFilters,
                response: response,
                documentCount: docCount,
                searchKeyword: query,
            });
        } catch (error) {
            this.setState({ error: error });
        }
    };
    render() {
        let { searchKeyword, isRenderLandingPage } = this.state;
        if (this.state.width >= 600 && this.state.width <= 1000) {
            return (<PubMedSearchAskQuestionIndex customWidth={this.state.width} customHeight={this.state.height} />)
        } else if (this.state.width > 1000) {
            return (
                <div>
                    <ErrorBoundary>
                        {this.state.userExists ? (<div className="pubmed-main-innerWrapper">
                            <PubMedNavigationMenu
                                pathName={window.location.pathname}
                                customWidth={this.state.width}
                                customHeight={this.state.height}
                            />
                            <div className="pubmed-main-section-container">
                                {this.state.isLoaded ?
                                    (
                                        isRenderLandingPage ? <PubMedSearchAskQuestionIndex
                                            customWidth={this.state.width}
                                            customHeight={this.state.height}
                                            searchKeyword={searchKeyword}
                                            handleChangeInSearch={this.handleChangeInSearch} /> :
                                            <>
                                                <div className="pubmed-main-section">
                                                    <div className="pubmed-main-left-section">
                                                        <PubMedLeftSearchBoxIndex
                                                            customWidth={this.state.width}
                                                            customHeight={this.state.height}
                                                            searchKeyword={searchKeyword}
                                                            handleChangeInSearch={this.handleChangeInSearch} />
                                                    </div>
                                                    <div className="pubmed-main-right-section">

                                                    </div>
                                                </div>
                                            </>) : (<div
                                                style={{ textAlign: "center", padding: "10%" }}
                                            >
                                                <img
                                                    src={imsLoader}
                                                    alt="Loading Data"
                                                    style={{ width: "10%" }}
                                                />
                                            </div>)}
                            </div>
                            <div style={{ margin: "0% 1%" }}>
                                <Footer
                                    customWidth={this.state.width}
                                    customHeight={this.state.height}
                                />
                            </div>{" "}
                        </div>) : (
                            <div style={{ textAlign: "center", padding: "10%" }}>
                                <img
                                    src={imsLoader}
                                    alt="Loading Data"
                                    style={{ width: "20%" }}
                                />
                            </div>
                        )}

                    </ErrorBoundary>
                </div>
            )

        }
    }
}
export default withRouter(PubMedSearch);